<template>
  <div class="cart-page">
    <div class="cart-card">
      <div class="cart-message">
        <div class="cart-message-state">
          <div>Dear {{ userInfo.nickname }}:</div>
          <div>Please reset your password!</div>
        </div>
      </div>
      <div class="email-box">
       <el-form :label-position="labelPosition" label-width="80px" :model="ruleForm"  :rules="rules" ref="ruleForm" >
        <el-form-item label="Password">
          <el-input v-model="ruleForm.Password"></el-input>
        </el-form-item>
         <el-form-item label="SecondPassword">
          <el-input v-model="ruleForm.SecondPassword"></el-input>
        </el-form-item>
         <el-form-item>
          <el-button  class="car-buy-btn" @click="resetPasswordFun('ruleForm')">Reset password</el-button>
        </el-form-item>
      </el-form>
      </div>
    </div>
  </div>
</template>

<script>
import { changePasswordApi } from "@/network/api.js";
import { mapGetters } from "vuex";
export default {
  name: "changePassword",
  data() {
    return {
       labelPosition: 'top',
        ruleForm: {
          Password: '',
          SecondPassword:''
        },
        rules: {
          Password: [
            { required: true, message: 'Please input your email', trigger: 'blur' }
          ],
          SecondPassword: [ { required: true, message: 'Please input your email', trigger: 'blur' }
          ],
        },
        changeToken:''

    };
  },
   watch: {
    $route: {
      handler() {
        this.changeToken = this.$route.query?.token;
        console.log('changeToken', this.changeToken);
      },
      deep: true,
      immediate: true,
    },
  },
  computed: {
    ...mapGetters(["userInfo"]),
  },
  created() {},
  methods: {
      async resetPasswordFun(formName) {

        if(!this.$refs[formName].validate){
          return
        }
        if(this.ruleForm.Password !== this.ruleForm.SecondPassword){
          this.$message.error('The two passwords are inconsistent');
          return
        }

        try {
      	const params = {
					password: this.ruleForm.Password,
          token: this.changeToken
				};
				const res = await changePasswordApi(params);
        if(res.code == '1'){
          this.$message.success('The password is reset successfully');
          this.$router.push('/Home');
          
        }else{
          this.$message.error('The password is reset failed');

        }
          // 处理结果
        } catch (error) {
          handleError(error);
        }
			
			}
  }

};
</script>

<style lang="scss" scoped>
.cart-page {
  width: 100%;
  height: 9.8rem;
  overflow: auto;
  margin-right: auto;
  margin-left: auto;
  text-align: center;
  padding: 20px 0;
  background: #fff;

  .cart-card {
    position: relative;
    width: 60%;
    margin: 0 auto;
    background-color: #fff;

    .cart-message {
      width: 100%;

      .cart-message-state {
        font-size: 18px;
        padding: 10px 0;
        text-align: left;
        color: #0e1422;
        line-height: 0.4rem;
      }
    }

    .email-box {
      .car-buy-btn {
        background-color: #ffb100;
        color: #fff;
        font-weight: bold;
        padding: 14px 24px;
      }
      .car-buy-btn:hover {
        background-color: #0e1422;
      }
    }
  }
}
</style>
<style lang="scss">
.el-form-item {
  text-align: left;
}
// .el-button--primary {
//   background-color: #ffb100 !important;
//   border-color: #ffb100 !important;
//   font-weight: 600;
// }
// .el-input__inner {
//   width: 50% !important;
// }
.el-input__inner:hover {
  // color: #ffb100;
  border-color: #ffb100;
  // width: 50% !important;
}
// .el-form-item {
//   margin-bottom: 0.1rem !important;
// }

.el-form--label-top .el-form-item__label {
  display: block;
}
</style>
